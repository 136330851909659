<template>
  <div class="o-slider">
    <div class="o-slider__controls has-text-centered">
      <button class="o-slider__controls__prev" ref="prevArrow">
        <slot name="prevArrow" />
      </button>
      <button class="o-slider__controls__next" ref="nextArrow">
        <slot name="nextArrow" />
      </button>
    </div>
    <div class="o-slider__items" ref="slider">
      <slot />
    </div>
  </div>
</template>

<script>
import $ from "jquery";
import { ref, onMounted } from "vue";
import "slick-carousel/slick/slick";

export default {
  name: "MSlider",
  props: {
    settings: {
      type: Object,
      required: false
    }
  },
  setup(props) {
    const slider = ref(null);
    const prevArrow = ref(null);
    const nextArrow = ref(null);

    onMounted(() => {
      let sliderSettings = {
        centerMode: true,
        centerPadding: "30px",
        infinite: false,
        mobileFirst: true,
        nextArrow: $(nextArrow.value),
        prevArrow: $(prevArrow.value),
        slidesToShow: 1,
        responsive: [
          {
            breakpoint: 1023,
            settings: {
              slidesToShow: 3
            }
          }
        ]
      };

      if (props.settings) {
        sliderSettings = Object.assign(sliderSettings, props.settings);
      }

      $(slider.value).slick(sliderSettings);
    });

    return {
      slider,
      prevArrow,
      nextArrow
    };
  }
};
</script>

<style lang="scss">
@import "~slick-carousel/slick/slick.scss";

.o-slider {
  position: relative;

  &__controls {
    @extend %position-absolute;
    @extend %zero-position;
    height: calc(100% - 24px);
    pointer-events: none;
    width: 100%;
    z-index: 1;

    &__next,
    &__prev {
      background: transparent;
      border: 0;
      cursor: pointer;
      height: 100%;
      outline: transparent;
      pointer-events: initial;
      transition: opacity 0.2s linear;

      &.slick-disabled {
        opacity: 0;
        pointer-events: none;
      }
    }

    &__next {
      // margin-left: calc(50vw - 22px);

      img {
        transform: rotateY(180deg);
      }
    }

    // &__prev {
    //   // margin-right: calc(50vw - 22px);
    // }
  }

  // @include media(">=desktop") {
  //   &__controls {
  //     &__next {
  //       // margin-left: calc(17vw - 12px);
  //     }

  //     &__prev {
  //       // margin-right: calc(17vw - 12px);
  //     }
  //   }
  // }
}
</style>
