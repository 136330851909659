<template>
  <ASection class="main-section pb-0 pl-0 pr-0" id="main-section">
    <main class="main" id="main">
      <OBenefits />
      <OMedicines />
      <PacienteArea />
      <ONews />
    </main>
  </ASection>
</template>

<script>
import ASection from "@/components/atoms/Section.vue";
import OBenefits from "@/components/organisms/Benefits.vue";
import OMedicines from "@/components/organisms/Medicines.vue";
import ONews from "@/components/organisms/News.vue";
import PacienteArea from "./PacienteArea.vue";

export default {
  components: {
    ASection,
    OBenefits,
    OMedicines,
    ONews,
    PacienteArea
  },
  methods: {
    isMobile() {
      if (
        /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
          navigator.userAgent
        )
      ) {
        return true;
      } else {
        return false;
      }
    }
  },
  name: "OMain"
};
</script>

<style lang="scss">
.main {
  margin-top: -96px;

  @include media(">=widescreen") {
    margin-top: -166px;
  }

  @include media(">=fullhd") {
    margin-top: -206px;
  }
}
html
  body
  div#app
  div#wrapper.t-fale-conosco.wrapper
  section#main-section.section.main-section.pb-0.pl-0.pr-0
  main#main.main
  div#participating-programs.container.b-medicines.is-fluid.pl-0.pr-0.pb-5
  div.o-slider.o-medicines-slider
  div.o-slider__controls.has-text-centered {
  display: flex !important;
  flex-direction: row !important;
  justify-content: space-between !important;
}

html
  body
  div#app
  div#wrapper.t-fale-conosco.wrapper
  section#main-section.section.main-section.pb-0.pl-0.pr-0
  main#main.main
  div.container.b-news.is-fluid.pl-0.pr-0
  div.o-slider.o-news-slider
  div.o-slider__controls.has-text-centered {
  display: flex !important;
  flex-direction: row !important;
  justify-content: space-between !important;
}
</style>
