<template>
  <Teleport to="body">
    <div v-if="showing" class="appModalWrap" :class="{ show: showing }">
      <div class="reveal-modal-bg" @click.self="close"></div>
      <div id="app-modal" class="reveal-modal" @click.self="close">
        <div class="app-text content mb-5">
          <div
            class="c-image is-flex is-justify-content-center is-align-items-center"
          >
            <figure class="image is-3by1">
              <img
                src="img/programa-bemperto.jpg"
                alt="Logo Programa BemPerto"
              />
            </figure>
          </div>
          <p
            class="is-size-5-mobile is-size-5-tablet is-size-4-fullhd has-text-weight-bold has-text-centered has-text-white"
          >
            Conheça Amgela, a nova assistente virtual do BemPerto.
          </p>
          <video ref="refVideo" id="video" class="video-int" controls>
            <source src="../../assets/amgela.mp4" type="video/mp4" />
          </video>
        </div>
        <small class="has-text-white c-approval">
          SC-BRA-CP-00639. Aprovado em Agosto de 2024.
        </small>
        <button
          id="close-app-modal"
          class="close-reveal-modal is-flex is-justify-content-center is-align-items-center"
          type="button"
          aria-label="close"
          @click.prevent="close"
        >
          <img src="../../assets/close.svg" alt="Fechar aviso" />
        </button>
      </div>
    </div>
  </Teleport>
</template>

<script>
import $ from "jquery";
import EventBus from "@/EventBus";
const sessionId = "appModal";

export default {
  computed: {
    isPrerendering: () => window.__PRERENDER_INJECTED
  },
  data() {
    return {
      inSession: null,
      showing: false
    };
  },
  created() {
    if (!this.isPrerendering) {
      this.inSession = !!sessionStorage.getItem(sessionId);
    }
  },
  mounted() {
    EventBus.on("openAppModal", () => {
      console.log("openAppModal event received");
      this.inSession = false;
      this.showing = true;
      this.openModal();
      console.log("openModal event executed");
    });
  },
  methods: {
    openModal() {
      if (this.inSession === false) {
        this.showing = !this.isPrerendering;
      }
    },

    close() {
      sessionStorage.setItem(sessionId, sessionId);

      this.showing = false;
    }
  },
  watch: {
    showing(value) {
      const isClipped = "is-clipped";

      if (value) {
        return $("html, body").addClass(isClipped);
      }

      $("html, body").removeClass(isClipped);
    }
  }
};
</script>

<style lang="scss">
.appModalWrap {
  display: none;

  .video-int {
    height: 100%;
    object-fit: contain;
    width: 100%;
  }

  &.show {
    display: block;
  }

  .content {
    p {
      color: #666;
      letter-spacing: 1px;
      text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.3);
    }

    .c-flip {
      transform: rotateY(180deg);
    }

    .c-image {
      background-color: #fff;
      border-radius: 8px;
      margin: -4rem auto 1rem;
      max-width: 220px;
      padding: 1.8rem 0.25rem;
      box-shadow: 0 20px 20px 0 rgba(0, 0, 0, 0.2);

      @include media(">=tablet") {
        margin: -4rem auto 2rem;
        max-width: 240px;
      }

      @include media(">=fullhd") {
        max-width: 260px;
        padding: 2rem 0.25rem;
      }

      img {
        display: block;
      }
    }

    .c-title {
      color: #0a629b;
    }
  }

  .c-img {
    @include media(">=desktop") {
      height: 480px;
      position: relative;

      img {
        height: 100%;
        object-fit: contain;
        width: 100%;
      }
    }
  }

  .c-approval {
    bottom: 1rem;
    font-size: 11px;
    left: 1rem;
    position: absolute;
  }

  .c-button {
    appearance: none;
    background: none;
    border: none;
    color: #666;
    font-family: inherit;
    font-weight: normal;
    padding: 0;
    text-rendering: optimizeLegibility;
  }

  .c-right {
    border-radius: 50%;
    bottom: 32px;
    right: 10px;
    position: absolute;
  }

  .c-left {
    background: radial-gradient(
      circle,
      rgba(255, 255, 255, 0.89) 50%,
      rgba(255, 255, 255, 0) 100%
    );
    border-radius: 50%;
    bottom: 32px;
    left: 10px;
    position: absolute;
  }

  .c-scroll {
    max-height: 80vh;
    overflow-x: hidden;
    overflow-y: auto;

    p {
      @include media(">=desktop") {
        padding: 0.5rem 1rem 0;
      }
    }
  }

  .reveal-modal-bg {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(0, 99, 195, 0.7);
    z-index: 1004;
    opacity: 1;
  }

  .reveal-modal,
  dialog {
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
    width: 94%;
    margin: 0 auto;
    padding: 1rem;
    border-radius: 6px;
    background: linear-gradient(90deg, #54d6fc, #78bbef, #ab9ffe, #c299ff);
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2), 0 2px 4px rgba(0, 0, 0, 0.1);
    opacity: 1;
    transform: translateY(-50%);
    z-index: 1005;

    @include media(">=tablet") {
      padding: 1rem 1.875rem 1.875rem;
    }

    @include media(">=desktop") {
      max-height: 80%;
      max-width: 720px;
      min-height: 380px;
    }

    @include media(">=widescreen") {
      max-height: none;
    }

    @include media(">=fullhd") {
      max-width: 830px;
    }

    p {
      font-family: inherit;
      margin-bottom: 1.25rem;
      text-rendering: optimizeLegibility;
    }
  }

  .reveal-modal .close-reveal-modal,
  dialog .close-reveal-modal {
    appearance: none;
    background: none;
    background: linear-gradient(45deg, #c299ff, #ab9ffe, #78bbef, #54d6fc);
    border: none;
    border-radius: 50%;
    bottom: 0;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1), 0 2px 4px rgba(0, 0, 0, 0.1) inset;
    cursor: pointer;
    font-weight: bold;
    height: 48px;
    left: 50%;
    margin-bottom: -2rem;
    padding: 12px;
    position: absolute;
    top: auto !important;
    transform: scale(1) translateX(-50%);
    transition: all 0.2s ease-in-out;
    width: 48px;

    &:hover {
      background: linear-gradient(-45deg, #c299ff, #ab9ffe, #78bbef, #54d6fc);
      transform: scale(1.12) translateX(-50%);
    }

    img {
      width: 100%;
    }
  }

  .reveal-modal > :first-child,
  dialog > :first-child {
    margin-top: 0;
  }
}
</style>
